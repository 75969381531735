import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate, Navigate } from 'react-router-dom';
import { settings } from './Settings';
import { Grid } from '@mui/material';
import moment from 'moment';
import { Header } from './components/Header';

const apiURL = settings.baseUrl;
const imageUriPath = settings.imageUriPath;
const db = settings.MongodbAppName;
const collection = 'batch';

const Component = () => {
  return <BatchForm />;
};

function BatchForm() {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const [batch, setbatch] = useState({
    title: '',
    description: '',
    repeat: 'Daily',
    repeat_on: [],
    timeAt: 'Am',
    repeat_after: 1,
    after_occurance: 1,
    approval_status: 'pending',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [centers, setCenters] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teachersSorted, setTeachersSorted] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    fetchCourses();
    fetchTeachers();
    fetchLanguages();
    fetchCenters();
  }, [id, type]);

  const fetchCenters = async () => {
    try {
      const response = await axios.get(`/read?db=${db}&collection=centers`, {
        baseURL: apiURL,
      });
      setCenters(response.data);
    } catch (error) {
      setError(true);
    }
  };

  const fetchCourses = async (batch) => {
    try {
      const collection = 'courses';
      const filter = { status: 'true' };
      const sort = { createdAt: -1 };
      const queryParams = new URLSearchParams({
        db,
        collection,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
      });
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      const fetchedCourses = response.data;
      setCourses(fetchedCourses);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError(true);
    }
  };
  useEffect(() => {
    hasCourse(batch?.course?._id);
  }, [batch]);

  const hasCourse = async (value) => {
    try {
      setbatch((prevSchema) => {
        const updatedSchema = { ...prevSchema };
        courses.map((item, index) => {
          if (item._id === value) {
            updatedSchema.course = item;
          }
        });
        setTeachersSorted([]); // Assuming teachersSorted is a state variable
        const teachersCopy = [...teachers]; // Assuming teachers is a state variable

        const filteredTeachers =
          teachersCopy &&
          teachersCopy.filter(
            (teacher) =>
              teacher.courses &&
              Object.values(teacher.courses).some(
                (course) => course._id === value
              )
          );
        setTeachersSorted(filteredTeachers); // Assuming setTeachersSorted is a state setter function
        return updatedSchema;
      });
    } catch (error) {
      console.error('Error in hasCourse:', error);
      setError(true);
    }
  };

  const hasCenter = async (e) => {
    const value = e.target.value;
    setbatch((prevSchema) => {
      const updatedSchema = { ...prevSchema };
      teachersSorted.map((item, index) => {
        if (item._id === value) {
          updatedSchema.teacher = item;
        }
      });
      const teacher = teachers.find((teacher) => teacher.name === value);

      if (teacher) {
        updatedSchema.center = teacher.center;
        updatedSchema.youtube = teacher.youtube;
      }
      return updatedSchema;
    });
  };
  const fetchTeachers = async (batch) => {
    try {
      const response = await axios.get(`/read?db=${db}&collection=teachers`, {
        baseURL: apiURL,
      });
      const fetchedTeachers = response.data;
      setTeachers(fetchedTeachers);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError(true);
    }
  };
  const fetchLanguages = async (batch) => {
    try {
      const response = await axios.get(`/read?db=${db}&collection=languages`, {
        baseURL: apiURL,
      });
      const fetchedCourses = response.data;
      setLanguages(fetchedCourses);
    } catch (error) {
      setError(true);
    }
  };
  const setBox = (e) => {
    setbatch((prevSchema) => {
      const updatedSchema = { ...prevSchema };
      updatedSchema.recurring = e.target.checked;
      return updatedSchema;
    });
  };
  const updateCourse = (key, value, current, ind) => {
    const updatedbatch = { ...batch };
    const updatedCourse = { ...courses };
    if (!current) {
      updatedbatch.courses[value._id] = { _id: value._id, title: value.title };
      updatedCourse[ind].state = true;
    } else {
      delete updatedbatch.courses[value._id];
      updatedCourse[ind].state = false;
    }
    setbatch(updatedbatch);
    //setCourses(updatedCourse);
  };

  const handleChangeRpeatOn = (value) => {
    var temp = [...batch.repeat_on];
    if (temp.indexOf(value) === -1) {
      temp.push(value);
    } else {
      temp = temp.filter((item) => value != item);
    }
    setbatch({ ...batch, repeat_on: temp });
  };
  const isRpeatOnMatch = (value) => {
    var temp = [...batch.repeat_on];
    if (temp.indexOf(value) === -1) {
      return false;
    }
    return true;
  };

  const addNew = async (e) => {
    e.preventDefault();
    const data = batch;
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      baseURL: apiURL,
    };
    try {
      if (!imageFile && !batch?.imagePath) {
        return setIsImageError(true);
      }
      if (!id || id === 'new') {
        await axios.post(
          '/insert',
          { image: imageFile, data, db, collection },
          config
        );
        navigate(-1);
      } else {
        await axios.put(
          '/update',
          { id, image: imageFile, data, db, collection },
          config
        );
        navigate(-1);
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleUploadImage = (e) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      const file = e.target.files[0];
      setIsImageError(false);
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  return (
    <div className="container-fluid">
      <Header />
      <div>
        <div className="container z-10 relative mt-[-500px] sm:mt-[-300px] sm:px-[40x] sm:py-[40px] px-2 py-6  mt-10 tds">
          <div className="">
            <div className="grid grid-cols-12 gap-y-10 gap-x-6">
              <div className="col-span-12">
                <div className="flex flex-col gap-y-3 items-center mb-10">
                  <div className="text-base text-[22px] sm:text-[35px] mb-2 group-[.mode--light]:text-white flex justify-center font-bold items-center">
                    Batch Registration
                  </div>
                  <p className="text-sm group-[.mode--light]:text-white flex justify-center font-bold items-center">
                    Enter your course details to register your batch.
                  </p>
                </div>

                <div className="container z-10 relative">
                  <div className="grid grid-cols-12 gap-y-10 gap-x-6">
                    <div className="col-span-12">
                      <div className="mt-3.5 grid grid-cols-12 xl:grid-cols-12 gap-y-7 lg:gap-y-10 gap-x-6">
                        <div className="relative flex flex-col col-span-12 lg:col-span-12 xl:col-span-12 gap-y-7">
                          <div className="flex flex-col p-5 box box--stacked">
                            <div>
                              <form onSubmit={addNew}>
                                <div className="border rounded-[0.6rem] dark:border-darkmode-400 relative mt-7 mb-4 border-slate-200/80">
                                  <div className="absolute left-0 px-3 ml-4 -mt-2 text-xs uppercase bg-white text-slate-500">
                                    <div className="-mt-px">batch</div>
                                  </div>
                                  <div className="px-5 py-2 mt-4 flex flex-col gap-3.5">
                                    <Grid
                                      container
                                      spacing={3}
                                      className="px-5"
                                    >
                                      <Grid item xs={12}>
                                        <div className="mt-6">
                                          <input
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleUploadImage}
                                            id="upload-image"
                                            className="hidden"
                                          />
                                          <label
                                            htmlFor="upload-image"
                                            className="w-full bg-gray-100"
                                          >
                                            <div className="h-64 bg-inherit mt-6 w-full flex flex-col items-center justify-center">
                                              {imageUrl && (
                                                <div className="flex flex-col items-center justify-center w-full">
                                                  <img
                                                    src={imageUrl}
                                                    alt="teacher-image"
                                                    className="max-h-44"
                                                  />
                                                </div>
                                              )}
                                              {!imageUrl && (
                                                <div className="flex flex-col items-center justify-center gap-1 w-full">
                                                  <h3 className="text-lg font-bold">
                                                    Batch Banner
                                                  </h3>
                                                  <div className="text-sm">
                                                    Please select a file to
                                                    add/update
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </label>
                                        </div>
                                        {isImageError && (
                                          <div className="bg-red-100 text-red-700 px-4 py-2 my-1">
                                            Batch banner is required.
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Course Name
                                            </label>
                                          </div>
                                          {courses && courses.length && (
                                            <select
                                              onChange={(e) =>
                                                hasCourse(e?.target?.value)
                                              }
                                              value={batch.course?._id}
                                              required
                                              className="w-full border-slate-300 rounded p-2.5"
                                            >
                                              <option value="">
                                                Select Course
                                              </option>
                                              {courses.map((cs, index) => (
                                                <option
                                                  key={index}
                                                  value={cs._id}
                                                >
                                                  {cs.title}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          {/* {teachersSorted &&
                                  batch.course &&
                                  teachersSorted.length && ( */}
                                          <>
                                            <div className="mt-4">
                                              <label
                                                htmlFor="regular-form-1"
                                                className="inline-block mb-2"
                                              >
                                                Teacher Name
                                              </label>
                                            </div>
                                            <select
                                              value={batch.teacher?._id}
                                              onChange={hasCenter}
                                              required
                                              className="w-full border-slate-300 rounded p-2.5"
                                              disabled={
                                                !batch.course &&
                                                !teachersSorted.length
                                              }
                                            >
                                              <option value="">
                                                Select Teacher
                                              </option>
                                              {teachersSorted.map(
                                                (cs, index) => (
                                                  <option
                                                    key={index}
                                                    value={cs._id}
                                                  >
                                                    {cs.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </>
                                          {/*  )} */}
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Language
                                            </label>
                                          </div>
                                          {languages && languages.length && (
                                            <select
                                              value={batch.language}
                                              onChange={(e) =>
                                                setbatch({
                                                  ...batch,
                                                  language: e.target.value,
                                                })
                                              }
                                              required
                                              className="w-full border-slate-300 rounded p-2.5"
                                            >
                                              <option value="">
                                                Select Language
                                              </option>
                                              {languages.map((cs, index) => (
                                                <option key={index}>
                                                  {cs.title}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Center
                                            </label>
                                          </div>

                                          {centers && centers.length && (
                                            <select
                                              value={batch.center}
                                              onChange={(e) =>
                                                setbatch({
                                                  ...batch,
                                                  center: e.target.value,
                                                })
                                              }
                                              required
                                              className="w-full border-slate-300 rounded p-2.5"
                                            >
                                              <option value="">
                                                Select Center
                                              </option>
                                              {centers.map((cs, index) => (
                                                <option key={index}>
                                                  {cs.title}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Assistant Teacher
                                            </label>
                                          </div>
                                          <input
                                            id="regular-form-1"
                                            type="text"
                                            placeholder="Input text"
                                            value={batch.assistant}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                assistant: e.target.value,
                                              })
                                            }
                                            className="w-full border-slate-300 rounded"
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Mode of Teaching
                                            </label>
                                          </div>

                                          <select
                                            value={batch.location}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                location: e.target.value,
                                              })
                                            }
                                            required
                                            className="w-full border-slate-300 rounded p-2.5"
                                          >
                                            <option value="">
                                              Select Mode of Teaching
                                            </option>
                                            <option>Online</option>
                                            <option>Physical</option>
                                            <option>Online & Physical</option>
                                          </select>
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              WhatsApp
                                            </label>
                                          </div>
                                          <input
                                            id="regular-form-1"
                                            type="text"
                                            placeholder="Input text"
                                            value={batch.whatsapp}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                whatsapp: e.target.value,
                                              })
                                            }
                                            className="w-full border-slate-300 rounded"
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Youtube
                                            </label>
                                          </div>
                                          <input
                                            id="regular-form-1"
                                            type="text"
                                            placeholder="Input text"
                                            value={batch.youtube}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                youtube: e.target.value,
                                              })
                                            }
                                            className="w-full border-slate-300 rounded"
                                          />
                                        </div>
                                      </Grid>
                                      {(batch.location === 'Online' ||
                                        batch.location ===
                                          'Online & Physical') && (
                                        <Grid item md={4} sm={6} xs={12}>
                                          <div>
                                            <div className="mt-4">
                                              <label
                                                htmlFor="regular-form-1"
                                                className="inline-block mb-2"
                                              >
                                                Meeting Link
                                              </label>
                                            </div>
                                            <input
                                              id="regular-form-1"
                                              type="text"
                                              placeholder="Input text"
                                              value={batch.meetingLink}
                                              onChange={(e) =>
                                                setbatch({
                                                  ...batch,
                                                  meetingLink: e.target.value,
                                                })
                                              }
                                              className="w-full border-slate-300 rounded"
                                              required
                                            />
                                          </div>
                                        </Grid>
                                      )}
                                      {(batch.location === 'Physical' ||
                                        batch.location ===
                                          'Online & Physical') && (
                                        <Grid item md={4} sm={6} xs={12}>
                                          <div>
                                            <div className="mt-4">
                                              <label
                                                htmlFor="regular-form-1"
                                                className="inline-block mb-2"
                                              >
                                                Address
                                              </label>
                                            </div>
                                            <input
                                              id="regular-form-1"
                                              type="text"
                                              placeholder="Input text"
                                              value={batch.address}
                                              onChange={(e) =>
                                                setbatch({
                                                  ...batch,
                                                  address: e.target.value,
                                                })
                                              }
                                              className="w-full border-slate-300 rounded"
                                              required
                                            />
                                          </div>
                                        </Grid>
                                      )}

                                      <Grid item xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Description
                                            </label>
                                          </div>
                                          <textarea
                                            id="regular-form-1"
                                            type="text"
                                            placeholder="Input text"
                                            value={batch.description}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                description: e.target.value,
                                              })
                                            }
                                            className="w-full border-slate-300 rounded"
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Registration Link
                                            </label>
                                          </div>
                                          <input
                                            id="regular-form-1"
                                            type="text"
                                            placeholder="Input text"
                                            value={batch.registrationLink}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                registrationLink:
                                                  e.target.value,
                                              })
                                            }
                                            className="w-full border-slate-300 rounded"
                                            required
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item md={4} sm={6} xs={12}>
                                        <div>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="regular-form-1"
                                              className="inline-block mb-2"
                                            >
                                              Available on calendar
                                            </label>
                                          </div>
                                          <input
                                            id="regular-form-1"
                                            placeholder="Input text"
                                            checked={
                                              batch.avaialbeOnCalendar ===
                                              'true'
                                            }
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                avaialbeOnCalendar: `${e.target.checked}`,
                                              })
                                            }
                                            className="h-10 w-10"
                                            type="checkbox"
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className="border rounded-[0.6rem] dark:border-darkmode-400 relative mt-7 mb-4 border-slate-200/80">
                                    <div className="absolute left-0 px-3 ml-4 -mt-2 text-xs uppercase bg-white text-slate-500">
                                      <div className="-mt-px">
                                        Course Timings
                                      </div>
                                    </div>
                                    <div className="px-5 py-2 mt-4 flex flex-row flex-wrap gap-3.5">
                                      <Grid
                                        container
                                        spacing={2}
                                        className="px-5"
                                      >
                                        <Grid item md={2} xs={12}>
                                          <div className="flex items-center h-full font-bold">
                                            When
                                          </div>
                                        </Grid>
                                        <Grid item md={3} sm={4.5} xs={12}>
                                          <input
                                            type="date"
                                            placeholder=""
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                date: e.target.value,
                                              })
                                            }
                                            required
                                            className="w-full border-slate-300 rounded"
                                            value={batch.date}
                                          />
                                        </Grid>
                                        <Grid item md={3} sm={4.5} xs={12}>
                                          <select
                                            value={batch.time}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                time: e.target.value,
                                              })
                                            }
                                            required
                                            className="w-full border-slate-300 rounded p-2.5"
                                          >
                                            <option value="">Time</option>
                                            {timeArray.map((value, index) => (
                                              <option value={value} key={index}>
                                                {value}
                                              </option>
                                            ))}
                                          </select>
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={12}>
                                          <select
                                            value={batch.timeAt}
                                            onChange={(e) =>
                                              setbatch({
                                                ...batch,
                                                timeAt: e.target.value,
                                              })
                                            }
                                            required
                                            className="w-full border-slate-300 rounded p-2.5"
                                          >
                                            <option value="Am">Am</option>
                                            <option value="Pm">Pm</option>
                                          </select>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        className="px-5"
                                      >
                                        <Grid item md={2} xs={12}>
                                          <div className="flex items-center h-full font-bold">
                                            Duration
                                          </div>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={12}>
                                          <div className="flex gap-1 items-center">
                                            <select
                                              value={batch.hours}
                                              onChange={(e) =>
                                                setbatch({
                                                  ...batch,
                                                  hours: e.target.value,
                                                })
                                              }
                                              required
                                              className="w-full border-slate-300 rounded p-2.5"
                                            >
                                              <option value="">Hours</option>
                                              {hoursArray.map(
                                                (value, index) => (
                                                  <option
                                                    value={value}
                                                    key={index}
                                                  >
                                                    {value}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <span className="font-bold">
                                              hr
                                            </span>
                                          </div>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={12}>
                                          <div className="flex gap-1 items-center">
                                            <select
                                              value={batch.minutes}
                                              onChange={(e) =>
                                                setbatch({
                                                  ...batch,
                                                  minutes: e.target.value,
                                                })
                                              }
                                              required
                                              className="w-full border-slate-300 rounded p-2.5"
                                            >
                                              <option value="">Minutes</option>
                                              {minutesArray.map(
                                                (value, index) => (
                                                  <option
                                                    value={value}
                                                    key={index}
                                                  >
                                                    {value}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <span className="font-bold">
                                              min
                                            </span>
                                          </div>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        container
                                        spacing={2}
                                        className="px-5"
                                      >
                                        <Grid item md={2} xs={0}></Grid>
                                        <Grid item md={8} xs={12}>
                                          <div className=" flex flex-row flex-wrap items-center gap-2">
                                            <div className="flex items-center gap-2">
                                              <div className="flex items-center">
                                                <input
                                                  type="checkbox"
                                                  onChange={(e) =>
                                                    setbatch({
                                                      ...batch,
                                                      recurring:
                                                        e.target.checked,
                                                    })
                                                  }
                                                  checked={batch?.recurring}
                                                  placeholder=""
                                                />
                                              </div>
                                              <label className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:w-60 xl:mr-14">
                                                <div className="text-left">
                                                  <div className="flex items-center">
                                                    <div className="font-medium">
                                                      Recurring
                                                    </div>
                                                  </div>
                                                </div>
                                              </label>
                                            </div>

                                            {batch?.recurring && (
                                              <div className="mt-1.5 xl:mt-3 leading-relaxed flex gap-2 font-bold text-md flex-wrap">
                                                <span>
                                                  {batch.repeat === 'Daily'
                                                    ? `Repeat Every ${batch.repeat_after} day,`
                                                    : batch.repeat === 'Weekly'
                                                    ? `Repeat Every ${batch.repeat_after} week,`
                                                    : batch.repeat === 'Monthly'
                                                    ? `Repeat Every ${batch.repeat_after} month,`
                                                    : ''}
                                                </span>{' '}
                                                {batch.by_date && (
                                                  <span>
                                                    {' '}
                                                    {` untill ${moment(
                                                      batch.by_date
                                                    ).format('MMM DD, YYYY')},`}
                                                  </span>
                                                )}
                                                {batch.after_occurance && (
                                                  <span>
                                                    {' '}
                                                    {`${batch.after_occurance} occurrence(s)`}
                                                  </span>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                      {batch.recurring && (
                                        <>
                                          <Grid
                                            container
                                            spacing={2}
                                            className="px-5"
                                          >
                                            <Grid item md={2} xs={0}></Grid>
                                            <Grid item md={2} sm={4} xs={12}>
                                              <div className="flex items-center h-full font-bold">
                                                Recurrance
                                              </div>
                                            </Grid>
                                            <Grid item md={2} sm={8} xs={12}>
                                              <select
                                                placeholder=""
                                                v-model="batch.repeat"
                                                required
                                                onChange={(e) =>
                                                  setbatch({
                                                    ...batch,
                                                    repeat: e.target.value,
                                                  })
                                                }
                                                value={batch.repeat}
                                                className="w-full border-slate-300 rounded p-2.5"
                                              >
                                                <option value="Daily">
                                                  Daily
                                                </option>
                                                <option value="Weekly">
                                                  Weekly
                                                </option>
                                                <option value="Monthly">
                                                  Monthly
                                                </option>
                                              </select>
                                            </Grid>
                                          </Grid>
                                          {batch.repeat === 'Weekly' && (
                                            <Grid
                                              container
                                              spacing={2}
                                              className="px-5"
                                            >
                                              <Grid item md={2} xs={0}></Grid>
                                              <Grid item md={2} sm={4} xs={12}>
                                                <div className="flex items-center h-full font-bold">
                                                  Rpeat On
                                                </div>
                                              </Grid>

                                              <Grid item sm={8} xs={12}>
                                                <div className="flex gap-3 flex-wrap items-center">
                                                  {[
                                                    'Su',
                                                    'Mo',
                                                    'Tu',
                                                    'We',
                                                    'Th',
                                                    'Fr',
                                                    'Sa',
                                                  ].map((item, index) => (
                                                    <div className="flex gap-2 flex-wrap items-center">
                                                      <input
                                                        id={item}
                                                        value={item}
                                                        type="checkbox"
                                                        checked={isRpeatOnMatch(
                                                          item
                                                        )}
                                                        onChange={() =>
                                                          handleChangeRpeatOn(
                                                            item
                                                          )
                                                        }
                                                        name="vertical_radio_button"
                                                      />
                                                      <label
                                                        htmlFor={item}
                                                        className="cursor-pointer ml-2"
                                                      >
                                                        {item}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              </Grid>
                                            </Grid>
                                          )}

                                          <Grid
                                            container
                                            spacing={2}
                                            className="px-5"
                                          >
                                            <Grid item md={2} xs={0}></Grid>
                                            <Grid item md={2} sm={4} xs={12}>
                                              <div className="flex items-center h-full font-bold">
                                                Repeat every
                                              </div>
                                            </Grid>
                                            <Grid item md={2} sm={8} xs={12}>
                                              <select
                                                placeholder=""
                                                onChange={(e) =>
                                                  setbatch({
                                                    ...batch,
                                                    repeat_after:
                                                      e.target.value,
                                                  })
                                                }
                                                value={batch.repeat_after}
                                                v-model="batch.repeat_after"
                                                required
                                                className="w-full border-slate-300 rounded p-2.5"
                                              >
                                                {[...Array(15)].map(
                                                  (_, index) => (
                                                    <option key={index}>
                                                      {index + 1}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </Grid>
                                          </Grid>

                                          <Grid
                                            container
                                            spacing={2}
                                            className="px-5"
                                          >
                                            <Grid item md={2} xs={0}></Grid>
                                            <Grid item md={2} sm={3} xs={12}>
                                              <div className="flex items-center h-full font-bold">
                                                End date
                                              </div>
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                              <div className="flex gap-1">
                                                <div className="flex items-center mr-2">
                                                  <input
                                                    id="checkbox-switch-1"
                                                    type="radio"
                                                    onChange={(e) =>
                                                      setbatch({
                                                        ...batch,
                                                        end_date_type:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value="1"
                                                    name="end_date"
                                                    checked={
                                                      batch.end_date_type ===
                                                      '1'
                                                    }
                                                    // className="transition-all duration-100 ease-in-out shadow-sm border-slate-300/80 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;[type='radio']]:checked:bg-primary/60 [&amp;[type='radio']]:checked:border-primary/50 [&amp;[type='radio']]:checked:border-opacity-10 [&amp;[type='checkbox']]:checked:bg-primary/60 [&amp;[type='checkbox']]:checked:border-primary/50 [&amp;[type='checkbox']]:checked:border-opacity-10 [&amp;:disabled:not(:checked)]:bg-slate-100 [&amp;:disabled:not(:checked)]:cursor-not-allowed [&amp;:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&amp;:disabled:checked]:opacity-70 [&amp;:disabled:checked]:cursor-not-allowed [&amp;:disabled:checked]:dark:bg-darkmode-800/50"
                                                    placeholder=""
                                                    required={
                                                      !batch.end_date_type
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="checkbox-switch-1"
                                                    className="cursor-pointer ml-2"
                                                  >
                                                    By
                                                  </label>
                                                </div>
                                                <div className="px-4 w-full">
                                                  <input
                                                    type="date"
                                                    onChange={(e) =>
                                                      setbatch({
                                                        ...batch,
                                                        by_date: e.target.value,
                                                      })
                                                    }
                                                    value={batch.by_date}
                                                    className="w-full"
                                                    placeholder=""
                                                    v-model="batch.by_date"
                                                    required={
                                                      batch.end_date_type ===
                                                      '1'
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                              <div className="flex gap-1">
                                                <div className="flex items-center mr-2">
                                                  <input
                                                    id="checkbox-switch-1"
                                                    type="radio"
                                                    name="end_date"
                                                    // className="transition-all duration-100 ease-in-out shadow-sm border-slate-300/80 cursor-pointer focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;[type='radio']]:checked:bg-primary/60 [&amp;[type='radio']]:checked:border-primary/50 [&amp;[type='radio']]:checked:border-opacity-10 [&amp;[type='checkbox']]:checked:bg-primary/60 [&amp;[type='checkbox']]:checked:border-primary/50 [&amp;[type='checkbox']]:checked:border-opacity-10 [&amp;:disabled:not(:checked)]:bg-slate-100 [&amp;:disabled:not(:checked)]:cursor-not-allowed [&amp;:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&amp;:disabled:checked]:opacity-70 [&amp;:disabled:checked]:cursor-not-allowed [&amp;:disabled:checked]:dark:bg-darkmode-800/50"
                                                    onChange={(e) =>
                                                      setbatch({
                                                        ...batch,
                                                        end_date_type:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value="2"
                                                    checked={
                                                      batch.end_date_type ===
                                                      '2'
                                                    }
                                                    placeholder=""
                                                    required={
                                                      !batch.end_date_type
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="checkbox-switch-1"
                                                    className="cursor-pointer ml-2"
                                                  >
                                                    After
                                                  </label>
                                                </div>
                                                <div className="relative w-80 px-4 flex-row flex items-center gap-2">
                                                  <select
                                                    placeholder=""
                                                    v-model="batch.after_occurance"
                                                    required
                                                    onChange={(e) =>
                                                      setbatch({
                                                        ...batch,
                                                        after_occurance:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      batch.after_occurance
                                                    }
                                                    className="w-full border-slate-300 rounded p-2.5"
                                                  >
                                                    {[...Array(60)].map(
                                                      (_, index) => (
                                                        <option key={index}>
                                                          {index + 1}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  <span className="text-md font-bold">
                                                    occurrence
                                                  </span>
                                                </div>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </>
                                      )}
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="px-5 py-2 mt-4 flex gap-3.5 w-full flex-row items-end justify-end">
                                  <button
                                    type="submit"
                                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-[#3b5998] border-[#3b5998] text-white dark:border-[#3b5998] w-32"
                                  >
                                    Save
                                  </button>
                                  <Link
                                    to="/"
                                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-[#4ab3f4] border-[#4ab3f4] text-white dark:border-[#4ab3f4] w-32"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;

const timeArray = [
  '1:00',
  '1:15',
  '1:30',
  '1:45',
  '2:00',
  '2:15',
  '2:30',
  '2:45',
  '3:00',
  '3:15',
  '3:30',
  '3:45',
  '4:00',
  '4:15',
  '4:30',
  '4:45',
  '5:00',
  '5:15',
  '5:30',
  '5:45',
  '6:00',
  '6:15',
  '6:30',
  '6:45',
  '7:00',
  '7:15',
  '7:30',
  '7:45',
  '8:00',
  '8:15',
  '8:30',
  '8:45',
  '9:00',
  '9:15',
  '9:30',
  '9:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
];

const hoursArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const minutesArray = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];
