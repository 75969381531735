import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Header } from './components/Header';
import { settings } from './Settings';
import { Grid, IconButton } from '@mui/material';
import moment from 'moment';

const apiURL = settings.baseUrl;
const imageUriPath = settings.imageUriPath;
const db = settings.MongodbAppName;

const collection = 'batch';
const file_url =
  'https://firebasestorage.googleapis.com/v0/b/soulters-api-db.appspot.com/o/';
function batchForm() {
  const navigate = useNavigate();
  const { course, date, id } = useParams();
  const [batch, setBatch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [centers, setCenters] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teachersSorted, setTeachersSorted] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getCourses();
      await getTeachers();
      await getLanguages();
      await getCenters();
      await getBatch();
      setLoading(false);
    };
    fetchData();
  }, []);

  const getBatch = async () => {
    setLoading(true);
    const collection = 'batch';
    const filter = {};
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(
        `/read/${id}?${queryParams.toString()}`,
        {
          baseURL: apiURL,
        }
      );
      console.log(response.data);
      setBatch(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };
  const getCenters = async () => {
    const filter = { approval_status: 'approved', course: course };
    try {
      const response = await axios.get(`/read?db=${db}&collection=centers`, {
        baseURL: apiURL,
      });
      setCenters(response.data);
    } catch (error) {
      setError(true);
    }
  };

  const getCourses = async (batch) => {
    setLoading(true);
    const collection = 'courses';
    const filter = { status: 'true', title: course };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getTeachers = async (batch) => {
    try {
      const response = await axios.get(`/read?db=${db}&collection=teachers`, {
        baseURL: apiURL,
      });
      const fetchedCourses = response.data;
      setTeachers(fetchedCourses);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError(true);
    }
  };
  const getLanguages = async (batch) => {
    try {
      const response = await axios.get(`/read?db=${db}&collection=languages`, {
        baseURL: apiURL,
      });
      const fetchedCourses = response.data;
      setLanguages(fetchedCourses);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError(true);
    }
  };
  return (
    <>
      {true && (
        <div className="container-fluid mb-16">
          <Header />
          <div className="container px-4" style={{ maxWidth: '1000px' }}>
            <div className="flex justify-start items-center mb-5 text-[#2271B1]">
              <Link
                to={`/`}
                className="mr-4 px-6 py-2 bg-[#2271B1] rounded-full text-white"
              >
                Back
              </Link>
              {/* <div className="text-[20px] font-bold mr-4 flex items-center justify-start">
                <div>{course}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 mx-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
                Batch # {batch.batch_no}
              </div> */}
            </div>

            <h1 className="font-bold text-xl py-5">
              {course} - Batch # {batch.batch_no}
              <hr />
            </h1>

            {batch?.course?.imagePath && (
              <div
                className="mb-8 flex image-download"
                style={{ position: 'relative' }}
              >
                {/*   <a
                  href={`${imageUriPath}${batch?.imagePath}`}
                  download={batch?.imagePath}
                  style={{ position: 'absolute', top: '2px', left: '3px' }}
                  className="download-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 32 32"
                  >
                    <path d="M24.873,12.009c-0.319-2.017-1.277-3.878-2.756-5.318c-0.396-0.384-1.028-0.378-1.414,0.018	c-0.353,0.363-0.36,0.915-0.059,1.302c-0.023-0.002-0.047-0.004-0.07-0.006c1.136,1.343-0.647,3.26-2.08,2.136	c-2.87-2.285-7.382-0.161-7.244,3.546c-0.003,0.219-0.049,0.427-0.13,0.616c-0.199,0.463-0.676,0.747-1.18,0.747H8.768	C6.135,15.049,4,17.184,4,19.817v0.415c0,2.559,2.021,4.63,4.552,4.746l-0.486-0.001C4.664,24.752,2,21.907,2,18.5	c0-1.7,1.063-5.73,5.502-6.415c0.383-0.059,0.697-0.334,0.807-0.706C9.242,8.212,12.199,6,15.5,6c0.852,0,1.686,0.141,2.478,0.419	c0.517,0.185,1.091-0.091,1.274-0.612c0.184-0.521-0.091-1.092-0.612-1.274C17.635,4.179,16.578,4,15.5,4	c-3.974,0-7.555,2.53-8.915,6.226C2.734,11.104,0,14.491,0,18.5c0,4.456,3.485,8.178,7.998,8.475L24.5,27c4.136,0,7.5-3.364,7.5-7.5	C32,15.49,28.836,12.204,24.873,12.009z M12.068,18.281H14V13.5c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5v4.781h1.932	c0.424,0,0.655,0.494,0.384,0.82l-3.932,4.719c-0.2,0.24-0.568,0.24-0.768,0l-3.932-4.719	C11.412,18.775,11.644,18.281,12.068,18.281z"></path>
                  </svg>
                </a> */}
                <img
                  src={`${imageUriPath}${batch?.imagePath}`}
                  style={{ maxWidth: '1000px', maxHeight: '500px' }}
                />
              </div>
            )}

            {batch.description && (
              <div className="my-5">
                <p>{batch.description}</p>
              </div>
            )}

            <div className="flex justify-start flex-row items-start w-full">
              <div>
                <div className="border-b border-[#BAD3E7] pbs-2 flex gap-2 mb-4">
                  <h2 className="text-lg   my-2  text-[#2271B1]">
                    Teacher Name
                  </h2>
                  <h2 className="text-lg   my-2  text-[#2271B1]">
                    {batch.teacher?.name || batch.teacher}
                  </h2>
                </div>
                {batch?.assistant && (
                  <h2 className=" font-bold text-xxl  ">
                    <span className="font-bold text-md">
                      {' '}
                      Assistant Teacher :{' '}
                    </span>{' '}
                    {batch.assistant}
                  </h2>
                )}

                {`${batch?.avaialbeOnCalendar}` === 'true' &&
                  batch.registrationLink && (
                    <div className="flex gap-2 items-center">
                      <span className="font-bold text-md">
                        {' '}
                        Registration Link :{' '}
                      </span>
                      <a
                        href={batch.registrationLink}
                        target="_blank"
                        className="text-blue-500"
                      >
                        {batch.registrationLink}
                      </a>
                    </div>
                  )}
                <div className="flex gap-2 items-center">
                  <span className="font-bold">Class Link : </span>
                  <a
                    href={batch.meetingLink}
                    target="_blank"
                    className="text-blue-500"
                  >
                    {batch.meetingLink}
                  </a>
                </div>

                {/*  */}
                <div className="text-xl mt-5">Detail</div>
                {/*  */}
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Course{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.course?.title || batch.course}
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Duration{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.hours} hours {batch.minutes} minutes
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Mode of Teaching{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.location}
                    </h2>
                  </Grid>
                  {batch.location === 'Physical' && batch.address && (
                    <Grid item md={6} xs={12}>
                      <h2 className=" text-sm">
                        Address{' '}
                        <span className="font-bold text-[30px] mx-2">.</span>
                        {batch.address}
                      </h2>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Time <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.time} {batch.timeAt}
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Start Date{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.date
                        ? moment(batch.date).format('Do MMM YYYY')
                        : ''}
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      End Date{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.by_date
                        ? moment(batch.by_date).format('Do MMM YYYY')
                        : ''}
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Recurrence{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.repeat}
                    </h2>
                  </Grid>
                  {batch.repeat === 'Weekly' && (
                    <Grid item md={6} xs={12}>
                      <h2 className=" text-sm">
                        Day(s){' '}
                        <span className="font-bold text-[30px] mx-2">.</span>
                        {[...batch.repeat_on].join(', ')}
                      </h2>
                    </Grid>
                  )}
                  {batch.repeat !== 'Weekly' && (
                    <Grid item md={6} xs={12}>
                      <h2 className=" text-sm">
                        Day{' '}
                        <span className="font-bold text-[30px] mx-2">.</span>
                        {moment(batch.date).format('ddd')}
                      </h2>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Language{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.language}
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Center{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      {batch.center}
                    </h2>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <h2 className=" text-sm">
                      Whatsapp{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      <a
                        href={`https://wa.me/${batch.whatsapp}`}
                        target="_blank"
                        className="text-blue-500"
                      >
                        {batch.whatsapp}
                      </a>
                    </h2>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className=" text-sm">
                      Youtube Channel{' '}
                      <span className="font-bold text-[30px] mx-2">.</span>
                      <a
                        href={batch.youtube}
                        target="_blank"
                        className="text-blue-500"
                      >
                        {batch.youtube}
                      </a>
                    </h2>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default batchForm;
