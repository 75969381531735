import { useRoutes, Outlet } from 'react-router-dom';
import Home from '../Home';
import Search from '../Search';
import AddBatch from '../AddBatch';
import ListBatch from '../ListBatch';
import DetailBatch from '../DetailBatch';
import HomeComponentNew from '../HomeComponentNew';
import AdvanceSearchComponent from '../AdvanceSearch';
const routes = [
  {
    path: '/',
    element: <HomeComponentNew />,
    showHeader: true,
  },
  {
    path: '/search',
    element: <AdvanceSearchComponent />,
    showHeader: true,
  },
  {
    path: '/search/:course',
    element: <Search />,
    showHeader: true,
  },
  {
    path: '/add-batch/new',
    element: <AddBatch />,
    showHeader: true,
  },
  {
    path: '/batch-list/:course/:date',
    element: <ListBatch />,
    showHeader: true,
  },
  {
    path: '/batch-list/:course/:date/:id',
    element: <DetailBatch />,
    showHeader: true,
  },
];

export const RouterApp = () => {
  return useRoutes(routes);
};

export const RoutesList = routes;
